import React from "react"
import { Link } from "gatsby"

import "./about-block.scss"

export default function AboutBlock() {
  return (
    <div className="at-about-block at-container">
      <div className="at-about at-about--hasimage">
        <div className="at-about__image"></div>
        <div className="at-about__contentwrap">
          <h3 className="at-about__title">
            Bonjour, je suis Diane, ta nouvelle coach de vie !
          </h3>
          <div className="at-about__content">
            <p>
              Et je suis très heureuse que tu visites aujourd’hui mon site
              internet. Mon accompagnement en développement personnel et mon
              travail sont dédiés aux personnes qui veulent s’inspirer et
              devenir inspirant·e·s.
            </p>
            <p>
              C’est à 28 ans que j’ai décidé de devenir celle que j’ai toujours
              voulue être au plus pronfond de moi : coach en développement
              personnel. En m’inspirant à moi-même, j’ai la fierté de pouvoir
              dire que je me respecte.
            </p>
            <p>
              Je travaille, à Montréal, avec des esprits motivés comme le tien
              certainement, qui veulent quitter leur quotidien métro, boulot,
              dodo et se lancer dans l'aventure d'une vie : la découverte de
              soi.
            </p>
            <p>
              Tu as des challenges ou des idées qui t’inspirent et te
              passionnent depuis des années mais tu n’as ni les connaissances,
              ni le temps nécessaire pour créer cette nouvelle façon de penser
              et cette vie dont tu rêves, sur mesure ?
            </p>
            <p>
              Alors je suis peut-être la personne pour t’aider à t’écouter et te
              découvrir en développement personnel. Durant chacune de nos
              séances, tout tournera autour de toi et de ce que tu veux faire.
              Ce sont des moments privilégiés avec et pour toi. Je ne suis là
              que pour te guider et te soutenir, pour te permettre de réaliser
              ce que tu souhaites.
            </p>
            <p>
              Nos rencontres, qu'elles soient en ligne ou en présentiel à
              Montréal, t’aideront à donner vie à tes projets et à te réaliser,
              aux travers de méthodes issues de différentes approches en
              coaching et développement personnel.
            </p>
          </div>
          <Link to="/coaching">
            <button type="submit" className="primary">
              Qui suis-je ?
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
