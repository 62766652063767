import React from "react"
import { Link } from "gatsby"

import "./hero-block.scss"

export default function HeroBlock() {
  return (
    <div className="at-hero">
      <div className="at-container">
        <div className="at-hero__content at-hero-content-right">
          <h1 className="at-hero__title">
            {" "}
            Es-tu prêt·e à t’inspirer toi-même et à devenir inspirant·e ?
          </h1>
          <p className="at-hero__text">
            J'accompagne toutes celles et ceux qui souhaitent découvrir la
            personne authentique qui raisonne au plus profond d'eux-même.
          </p>
          <Link to="/contact">
            <button type="submit" className="primary">
              Rencontrons nous !
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
