import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import HeroBlock from "../components/hero-block/hero-block"
import AboutBlock from "../components/about-block/about-block"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <>
      <SEO keywords={[`diane le blanc`, `coaching`]} />
      <div className="home at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <HeroBlock />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">
                As-tu du mal à apprécier ta vie telle qu’elle est ?
              </h2>
              <div class="at-page__title-divider"></div>
              <p>
                <img
                  src={
                    "/images/young-woman-taking-stock-of-her-surroundings.jpg"
                  }
                  className="size-large alignright"
                  alt="Une jeune femme fait le point sur son environnement"
                />
                Tu es abonné·e à tous les comptes Instagram de coachnig ou coach
                de vie, d'accompagnement personnel, de pensées positives. Tu as
                lu des livres de développement personnel qui t’ont inspirés 2
                jours... Tu as beau essayé de chercher le positif et de sourire
                continuellement face à tes ami·e·s et collègues de travail pour
                ne pas les inquiéter mais finalement tu exploses et réalises que
                tu n’es pas toi-même et pas heureux·se. Je te comprends car
                j’étais comme toi :
              </p>
              <ul>
                <li>
                  Tu as l’impression d'étouffer dans ta vie et tu ne vois pas
                  bien comment jongler entre ta vie personnelle et
                  professionnelle ;
                </li>
                <li>
                  Tu es persuadé·e d’avoir fait les mauvais choix et tu ne sais
                  plus comment t'y prendre pour y voir plus clair et sortir de
                  ton propre labyrinthe ;
                </li>
                <li>
                  Tu crois qu’on rit de toi, tu crains le regard des autres, la
                  critique et manques profondément de confiance en toi ;
                </li>
                <li>
                  Tu as envie de hurler pour faire sortir toutes ces émotions en
                  toi, partir loin sans prévenir personne et tout plaquer pour
                  te découvrir et découvrir le monde ;
                </li>
                <li>
                  Tu es comme une marmite en ébullition mais tu te sens
                  épuisé·e, tu procrastines, tu laisses ta vie défiler sous tes
                  yeux sans rien faire.
                </li>
              </ul>
              <p>
                Rassure-toi, tu es au bon endroit ! Je suis coach en
                développement personne et tu as juste besoin d'entraînement et
                d’accompagnement. Ensemble, tu trouveras ton alignement
                personnel.
              </p>
              <p>
                Es-tu prêt·e à t'investir pour que ta vie soit à la fois source
                d’inspiration pour toi et pour les autres ?
              </p>
            </div>
          </article>
        </Layout>
        <AboutBlock />
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
